import {createUrl} from './base-url';

export const AuthUrls = {
  getAllUserList: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getAllUserListQuery'),
  removeUser: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/removeUser/:UserId'),
  cloneTeacher: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/copyTeacherDataCommand'),
  login: (baseUrl: string) => createUrl(baseUrl, 'Account/loginUser'),
  createUser: (baseUrl: string) => createUrl(baseUrl, 'Account/createUser'),
  updateUser: (baseUrl: string) => createUrl(baseUrl, 'Account/updateUser'),
  deleteUser: (baseUrl: string) => createUrl(baseUrl, 'Account/updateUser/:id'),
  getUserProfile: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getUserdata'),
  forgotPassword: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/forgotPassword'),
  confirmEmail: (baseUrl: string) => createUrl(baseUrl, 'Account/confirmEmail'),
  resetPassword: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/resetPassword'),
  submitMyInterest: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/submitMyInterest'),
  inviteBetaUser: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/inviteBetaUser'),
  loginWithoutPasswordCommand: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/loginWithoutPasswordCommand'),
  togglePreviledgeFlag: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/togglePreviledgeFlag/:userId'),
  issueCompleteHourCertificate: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/issueCompleteHourCertificate'),
  getCertificateDetail: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getCertificateDetail/:UniqueKey'),
  addRegistrationRequests: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/addRegistrationRequests'),
  getAllRegistrationRequest: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getAllRegistrationRequest'),
  sendRegistrationEmailCommand: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/sendRegistrationEmailCommand/:Id'),
  getRegistrationRequestById: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getRegistrationRequestById/:Key'),
  addRequestForDemo: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/addRequestForDemo'),
  getStateOrRegions: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getStateOrRegions'),
  getAllRoles: (baseUrl: string) =>
    createUrl(baseUrl, 'Account/getAllRoles'),
  getUserUnreadCount: (baseUrl: string) => createUrl(baseUrl, 'Account/getUserUnreadCount'),
};
